@import "../config"
@import "styles/config"

.header
    position: relative
    width: 100%
    min-width: $min-width
    height: 94px
    z-index: 999
    +max-width($desktop)
        height: 92px
    +max-width($lg-tablet)
        height: 84px
    +max-width($tablet)
        height: 60px


    &__wrapper
        position: fixed
        min-width: $min-width
        max-width: 2000px
        width: 100%
        left: 50%
        transform: translateX(-50%)
        right: 0
        top: 0
        background: $white
        padding: 20px 0

        &--transparent
            background: rgba(255, 255, 255, .6)
            +max-width($mobail)
                background: rgba(255, 255, 255, 1)

        +max-width($xl-desktop)
            padding: 20px 0
        +max-width($tablet)
            padding: 15px 40px
        +max-width($mobail)
            padding: 15px


    &__grid
        position: relative
        display: flex
        justify-content: space-between
        align-items: center


    &__item
        position: relative
        display: block

        &--burger
            display: flex
            min-width: 80px
            max-width: 80px
            width: 80px
            align-items: center
            justify-content: center
            z-index: 999
            +max-width($tablet)
                min-width: 20px
                max-width: 20px
                width: 20px


        &--center
            max-width: 1400px
            width: 100%
            margin: 0 auto
            +max-width($tablet)
                order: -1


        &--lang
            display: flex
            min-width: 80px
            max-width: 80px
            width: 80px
            align-items: center
            justify-content: center
            +max-width($tablet)
                display: none


    &__mobail-wrap
        position: absolute
        left: 0
        right: 0
        bottom: 0
        top: 0


.header-center
    position: relative
    display: flex
    align-items: center
    justify-content: space-between

    &__item
        position: relative


        &--left
            display: flex
            align-items: center


        &--right
            display: flex
            align-items: center
            +max-width($tablet)
                display: none


    &__menu
        +max-width($lg-tablet)
            display: none


    &__btn
        position: relative
        padding: 0 0 0 80px
        +max-width($xl-desktop)
            padding: 0 0 0 40px


.header-logo
    position: relative
    display: inline-block
    transition: opacity .3s ease

    &:hover
        opacity: .7

    &__img
        position: relative
        max-height: 52px
        height: 100%
        min-width: 240px
        +max-width($desktop)
            width: 100%
            max-height: 40px
        +max-width($tablet)
            object-fit: cover
            min-width: 180px


        &--black
            display: block

        &--white
            display: none

        &--bottom
            margin: 5px 0 0 0
            min-width: auto
            width: auto

        &--marketing
            margin: 0.75rem 0 0 0

.header-lang
    position: relative
    display: inline-block
    font: 600 16px/130% $font
    color: $black
    text-transform: uppercase
    +max-width($desktop)
        font: 600 14px/130% $font


// Бургер меню
// ===========
.header-burger
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    z-index: 99

    &__wrap
        position: relative
        width: 20px
        height: 14px
        cursor: pointer

    &:hover
        .header-burger__global
            border-top: 2px solid $link


    &.active
        .header-burger
            &__global

                &--top
                    backface-visibility: hidden
                    top: calc(50% - 1px)
                    transform: rotate(45deg)
                    transition: .3s .3s

                &--middle
                    opacity: 0

                &--bottom
                    backface-visibility: hidden
                    top: calc(50% - 1px)
                    transform: rotate(-405deg)
                    transition: .3s .3s


    &__global
        backface-visibility: hidden
        position: absolute
        left: 0
        border-top: 2px solid $color
        width: 20px
        transition: .3s

        &--top
            top: 0


        &--middle
            top: 6px


        &--bottom
            top: 12px
